<template>
  <div class="index-list">
    <m-infinite-scroll
      :load="get"
      :page="page"
      :pagination="pagination"
      icon="person"
      resource="members"
    >
      <div
        v-for="member in members"
        :key="member.username"
        class="row-item"
      >
        <member-card
          :member="member"
          org
        />
      </div>
    </m-infinite-scroll>

    <q-page-sticky position="bottom-right" :offset="[20, 20]">
      <q-fab
        color="green"
        icon="person_add"
        direction="up"
      >
        <q-fab-action
          color="primary"
          icon="add"
          @click="createModal = true"
        />

        <q-fab-action
          color="blue"
          icon="cloud_upload"
          @click="uploadModal = true"
        />
      </q-fab>
    </q-page-sticky>

    <member-create
      v-model="createModal"
      :organisation="$route.params.organisation"
      @created="clear"
      @switch-to-upload="switchToUpload"
    />
    <member-upload
      v-model="uploadModal"
      :organisation="$route.params.organisation"
      @created="clear"
    />
  </div>
</template>

<script>
import memberCard from 'pages/members/list/member-card'
import memberCreate from '../../members/list/member-create'
import memberUpload from '../../members/list/member-upload'

export default {
  components: {
    memberCard,
    memberCreate,
    memberUpload
  },
  data () {
    return {
      createModal: false,
      uploadModal: false,
      page: null,
      pagination: null,
      members: [],
      columns: ['Name', 'Email', 'Status']
    }
  },
  created () {
    this.$store.dispatch('members/reset')
  },
  methods: {
    clear () {
      this.page = 0
      this.members = []
    },
    get (page) {
      this.page = page
      return this.$store.dispatch('members/loadUsers', {
        orgId: this.$route.params.organisation,
        params: { page }
      })
        .then(response => {
          this.members = this.members.concat(response.data)
          this.pagination = response.meta.pagination
        })
    },
    switchToUpload () {
      this.uploadModal = true
      this.createModal = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-list
  overflow auto
  margin auto
  .row-item:first-child
    margin-top 16px

  @media (min-width 768px)
    padding 0 3rem

.row-item
  width 100%
  @media (min-width 768px)
    width 75%
</style>
