<template>
  <q-drawer
    v-model="ui.quasar.sides.right"
    content-class="shadow-2"
    overlay
    side="right"
  >
    <div v-if="org">
      <div class="top-sheet bg-deep-purple-4 shadow-1">
        <q-btn
          round
          flat
          color="white"
          size="sm"
          icon="close"
          style="position: absolute; top: 8px; right: 8px"
          @click="close"
        />
        <div class="row q-col-gutter-xs">
          <div class="col-sm-10">
            <h5 class="text-white">
              {{ org.name }}
            </h5>
          </div>
        </div>
      </div>
      <div class="peak-body">
        <div class="actions">
          <note-dialog :create="create" />
          <q-btn
            color="primary"
            class="btn-view bg-light-grey"
            label="View Organisation"
            @click="view"
          />
        </div>
      </div>
    </div>
  </q-drawer>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import noteDialog from '@/MResourceNotes/noteDialog'
import { addNote } from 'api/organisations'
export default {
  components: {
    noteDialog
  },
  computed: {
    ...mapGetters({
      ui: 'ui',
      user: 'user'
    }),
    org () {
      return this.$store.getters['organisations/getSelectedOrg'] || null
    }
  },
  methods: {
    view () {
      this.$router.push({ name: 'organisations-view', params: { organisation: this.org.slug, tab: 'profile' } })
    },
    create (content) {
      return addNote(this.org.slug, {
        body: content,
        author_id: this.user.username
      }).then(note => {
        this.$q.notify({ color: 'positive', icon: 'mdi-check', position: 'bottom', message: 'Note added!' })
      })
    },
    close () {
      this.$store.dispatch('toggleRightSidebar')
    }
  }
}
</script>

<style lang="stylus" scoped>
.top-sheet
  padding 0.75rem
  position relative
  z-index 100
.avatar
  width 36px
  height 36px
h5
  font-size 18px
  line-height 1.2
.last-active
  font-size 14px
.actions
  display flex
  flex-direction column
  justify-content center
  align-items center
  margin-bottom 15px
.btn-view
  margin-top 15px
  width 182px
.peak-body
  height calc(100vh - 126px)
  overflow auto

.total-budget
  height 25px
  width 100%
  background lightgrey
.content-spend
  height 25px
  display inline-block
sub
  margin-left 16px
</style>
