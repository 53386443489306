<template>
  <q-page>
    <div class="row">
      <div
        v-if="resource"
        class="resource-info col-xs-12"
      >
        <div class="top-sheet bg-primary row">
          <div class="col-sm-9">
            <h4 class="text-white">
              {{ resource.name }}
            </h4>
            <div class="text-white">
              Joined us on {{ resource.created_at.date | date }}
            </div>
          </div>
          <div class="col-sm-1 text-center">
            <q-btn
              v-if="!resource.is_blocked"
              class="text-white"
              round
              dense
              icon="block"
              style="margin-right: 15px;"
              @click.stop="block"
            >
              <q-tooltip>
                Block
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="resource.is_blocked"
              class="text-white"
              round
              dense
              icon="check_circle_outline"
              style="margin-right: 15px;"
              @click.stop="unblock"
            >
              <q-tooltip>
                Unblock
              </q-tooltip>
            </q-btn>
          </div>
          <div class="col-sm-2">
            <q-btn
              :label="resource.has_account ? 'Disable account payment' : 'Enable account payment'"
              class="text-white"
              @click="setAccount"
            />
          </div>
        </div>
        <q-tabs
          align="justify"
          class="shadow-2 text-white bg-primary-tint"
        >
          <q-route-tab

            to="profile"
            name="profile"
            icon="business"
          >
            Profile
          </q-route-tab>
          <q-route-tab
            v-if="resource.attributes.content_config"
            to="contents"
            name="contents"
            icon="commute"
          >
            Contents
          </q-route-tab>
          <q-route-tab

            to="members"
            name="members"
            icon="person"
          >
            Members
          </q-route-tab>
          <q-route-tab

            to="bookings"
            name="bookings"
            icon="receipt"
          >
            Bookings
          </q-route-tab>
          <q-route-tab

            to="billing"
            name="billing"
            icon="payment"
          >
            Pricing Schedule
          </q-route-tab>
          <q-route-tab

            to="notes"
            name="notes"
            icon="note"
          >
            Notes
          </q-route-tab>
          <!-- <q-route-tab to="pricing"  name="pricing">Pricing</q-route-tab> -->
          <q-route-tab

            to="history"
            name="history"
            icon="history"
          >
            History
          </q-route-tab>
        </q-tabs>
        <router-view
          :key="$route.path"
          :resource="resource"
        />
      </div>
    </div>
  </q-page>
</template>
<script type="text/javascript">
import store from 'store'
import date from 'utils/date-time'

export default {
  name: 'OrganisationView',
  filters: {
    date (data) {
      return date.toShortDate(data)
    }
  },
  computed: {
    resource () {
      return this.$store.getters['organisations/getOrg'](this.$route.params.organisation)
    }
  },
  beforeRouteEnter (to, from, next) {
    return store.dispatch('organisations/loadOrg', {
      orgId: to.params.organisation,
      params: {
        include: 'pricingschedule'
      }
    })
      .then(() => next())
      .catch(e => { console.log(e) })
  },
  methods: {
    setAccount () {
      const action = this.resource.has_account ? 'disable' : 'enable'
      const string = this.resource.has_account ? 'Disable account' : 'Enable account'
      this.$q.dialog({
        title: string,
        cancel: true,
        color: 'secondary'
      }).onOk(data => {
        this.$store.dispatch('organisations/setAccount', {
          org: this.resource.slug,
          action
        })
      })
    },
    unblock () {
      this.$q.dialog({
        title: 'Confirm',
        message: 'Are you sure you want to unblock this organisation',
        ok: 'Confirm',
        cancel: 'Cancel'
      })
        .onOk(() => {
          this.$store.dispatch('organisations/unblock', this.resource.slug)
            .then(() => {
              this.$q.notify({
                message: `Organisation unblocked`,
                color: 'positive',
                icon: 'mdi-check'
              })
            })
            .catch(e => {
              this.$q.notify({
                message: e.data.title,
                color: 'negative',
                icon: 'clear'
              })
            })
        })
        .catch(() => {})
    },
    block () {
      this.$q.dialog({
        title: 'Confirm',
        message: 'Are you sure you want to block this organisation',
        ok: 'Confirm',
        cancel: 'Cancel'
      })
        .onOk(() => {
          this.$store.dispatch('organisations/block', this.resource.slug)
            .then(() => {
              this.$q.notify({
                message: `Organisation blocked`,
                color: 'positive',
                icon: 'mdi-check'
              })
            })
            .catch(e => {
              this.$q.notify({
                message: e.data.title,
                color: 'negative',
                icon: 'clear'
              })
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="stylus" scoped>
.avatar
  margin-right 15px
h4, h6
  display inline-block
  vertical-align middle
h6
  text-transform uppercase
.user-meta
  margin-left 70px
.resource-info-list
  width 30%
  position relative
  z-index 200
.top-sheet
  padding 1.6rem 2rem
  padding-bottom 0
  position relative
  z-index 150
  min-height 135px
.q-tabs
  position relative
  z-index 100
.tab-body
  height calc(100vh - 233px)
  overflow auto
  z-index 50
  position relative
</style>
