<template>
  <div class="pricing-schedules">
    <q-card class="bg-white pricing-schedule">
      <q-card-section class="bg-primary">
        Uplift
      </q-card-section>
      <q-card-section>
        <p class="text-body1">
          Uplift is a percentage increased applied directly to the visible price of a travel item being booked. This is different to the pricing schedule which adds a fee as an additional line to an invoice.
        </p>
        <q-input v-model.number="schedule.flight.uplift_percentage" type="number" label="Flight uplift percentage" />
        <q-input v-if="!isUndeclaredOrNull(schedule.ridehailing.uplift_percentage)" v-model.number="schedule.ridehailing.uplift_percentage" type="number" label="Taxi/Ridehailing uplift percentage" />
        <q-input v-if="!isUndeclaredOrNull(schedule.onwardtravel.uplift_percentage)" v-model.number="schedule.onwardtravel.uplift_percentage" type="number" label="Onward Travel uplift percentage" />
      </q-card-section>
    </q-card>
    <q-card class="bg-white pricing-schedule">
      <q-card-section class="bg-primary">
        Pricing Schedule
      </q-card-section>
      <q-card-section>
        <p class="text-body1">
          Pricing schedule is a way to manipulate how fees are applied to each content type. The base percentage is used if the amount falls between the min and the max. If the percentage is below the min then the minimum fee value is used. If the percentage exceeds the max then the maximum fee value is used.
        </p>
        <p class="text-body1">
          To achieve a flat fee - Set base percentage to 0 and minimum fee to the desired flat fee
        </p>
      </q-card-section>
      <q-card-section>
        <div v-for="(t, k) in schedule" :key="k" class="pricing">
          <div class="row gutter-sm">
            <div class="col-xs-12 col-sm-3">
              <div class="type">
                {{ k }}
              </div>
            </div>
            <div class="col-xs-6 col-sm-3">
              <q-input v-model="t.percentage" label="Base percentage" />
            </div>
            <div class="col-xs-6 col-sm-3">
              <money-field
                v-model="t.min"
                label="Minimum fee"
              />
            </div>
            <div class="col-xs-6 col-sm-3">
              <money-field
                v-model="t.max"
                label="Maximum fee"
              />
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
    <span>
      <q-btn
        round
        size="lg"
        color="primary"
        class="fixed"
        icon="done"
        style="right: 18px; bottom: 18px"
        @click="save"
      />
    </span>
  </div>
</template>

<script>
import moneyField from 'components/MFields/money.vue'
export default {
  components: { moneyField },
  props: ['resource'],
  data () {
    return {
      schedule: null
    }
  },
  created () {
    const scheduleObject = {}
    Object.keys(this.resource.pricingschedule.data).forEach(k => {
      scheduleObject[k] = {
        min: this.resource.pricingschedule.data[k].min ? this.resource.pricingschedule.data[k].min.amount : null,
        max: this.resource.pricingschedule.data[k].max ? this.resource.pricingschedule.data[k].max.amount : null,
        percentage: this.resource.pricingschedule.data[k].percentage,
        uplift_percentage: this.resource.pricingschedule.data[k].uplift_percentage,
        type: k
      }
    })
    this.schedule = scheduleObject
  },
  methods: {
    save () {
      console.log(this.schedule)
      return this.$store.dispatch('organisations/updatePricing', {
        org: this.$route.params.organisation,
        schedule: this.schedule
      })
    },
    isUndeclaredOrNull (value) {
      return typeof value === 'undefined' // uncomment for null || (typeof value === 'object' && !value)
    }
  }
}

</script>

<style lang="stylus" scoped>
.pricing-schedules
  max-width 100%
  width 768px
  margin auto
p
  margin-top 16px
  margin-bottom 16px
.pricing-schedule
  max-width 768px
  margin auto
  margin-top 30px
.pricing
  padding 10px 0
.pricing:nth-child(odd) {
  background-color #F5F5F5
}
.type
  text-transform capitalize
  margin-top 12px
  margin-left 12px
</style>
