<template>
  <div :class="small ? 'small-organisation-list' : ''" class="organisation-list">
    <m-resource-top-sheet :small="small" :columns="columns" title="Organisations">
      <template slot="tools">
        <div
          :class="!small ? 'q-col-gutter-sm' : ''"
          class="row tools"
        >
          <div class="col-sm-4 gt-sm">
            <q-select
              v-model="filters.blocked"
              :options="block_statuses"
              label="Status"
              emit-value
              map-options
            >
              <template v-slot:option="scope">
                <q-item
                  v-bind="scope.itemProps"
                  v-on="scope.itemEvents"
                >
                  <q-item-section>
                    <q-item-label label>
                      {{ scope.opt.label }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="col-xs-12 col-sm-8">
            <q-input
              v-model="searchTerm"
              label="Search for an organisation"
              class="search"
              debounce="500"
              @input="runSearch"
            />
          </div>
          <organisation-create />
        </div>
      </template>
    </m-resource-top-sheet>
    <q-scroll-area class="index-list">
      <m-infinite-scroll
        :load="filter"
        :page="filters.page"
        :pagination="pagination"
        icon="business"
        resource="organisations"
      >
        <div v-for="b in organisations" :key="b.reference" class="row-item">
          <organisation-card :organisation="b" :selected="selected" :small="small" />
        </div>
      </m-infinite-scroll>
    </q-scroll-area>
  </div>
</template>

<script type="text/javascript">
import _ from 'lodash'
import organisationCreate from './organisation-create'
import organisationCard from './organisation-card'
import { mapGetters } from 'vuex'

export default {
  name: 'Organisations',
  components: {
    organisationCreate,
    organisationCard
  },
  props: {
    small: {
      type: Boolean
    },
    selected: {
      type: String
    }
  },
  data () {
    return {
      searchTerm: '',
      createOpen: false,
      organisations: [],
      filters: {
        page: 0,
        limit: 10,
        search: '',
        blocked: false
      },
      pagination: null,
      columns: ['Name', 'Key Contact', 'Telephone', 'Status'],
      block_statuses: []
    }
  },
  computed: {
    ...mapGetters({
      consumerToken: 'partner/consumerToken'
    })
  },
  watch: {
    'filters.blocked' () {
      this.clear()
    },
    consumerToken: {
      deep: true,
      handler () {
        this.clear()
        this.filter()
      }
    }
  },
  created () {
    this.$store.dispatch('organisations/reset')
    this.block_statuses = this.$store.getters['individuals/getSelect']('blockStatuses')
  },
  methods: {
    clear () {
      this.filters.page = 0
      this.organisations = []
    },
    runSearch (search, done) {
      if (search.length || (!search.length && this.filters.search.length)) {
        this.clear()
        this.filters.search = search
      }
      done()
    },
    filter (page) {
      this.filters.include = 'members'
      this.filters.page = page
      return this.$store.dispatch('organisations/loadOrgs', {
        params: _.pickBy(this.filters)
      })
        .then(response => {
          this.organisations = this.organisations.concat(response.data)
          this.pagination = response.meta.pagination
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-list
  height calc(100vh - 185px)
  @media (min-width 768px)
    padding 0 3rem
    height calc(100vh - 271px)

  .row-item:first-child
    margin-top 16px

.index-head, .resource-card
  @media (min-width 768px)
    width 75%

.resource-card
  max-height 82px
  position relative

.row-item
  width 100%

.tools
  @media (min-width: 768px)
    width 50%

.search
  margin-bottom 10px
  @media (min-width: 768px)
    margin-bottom 0px
</style>
